import chroma from "chroma-js";

export const colors = ["#e4a05a", "#eace53", "#59b534"];

const scale = chroma.scale(colors);

const norm = (stoke) => stoke / 100;

export const climbingStokeToColor = (stoke: number): string => {
  return scale(norm(stoke)).hex();
};

export const stokeToString = (stoke: number): string => {
  if (stoke < 33) {
    return "Poor";
  }

  if (stoke < 66) {
    return "Fair";
  }

  return "Good";
};
