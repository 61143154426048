import { Stack, Typography, Grid, Box, BoxProps, Button } from "@mui/material";
import { format } from "date-fns";
import { Location } from "../types";
import { climbingStokeToColor } from "../api/stoke";
import CancelIcon from "@mui/icons-material/Cancel";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 2,
        textAlign: "center",
        ...sx,
      }}
      {...other}
    />
  );
}

const Bubbles = ({
  sx = {},
  colors = [],
}: {
  sx?: any;
  colors: Array<string>;
}) => {
  return (
    <Stack direction={"row"} spacing={0.5}>
      {colors.map((color) => (
        <Box
          sx={{
            borderRadius: "15px",
            width: "100%",
            height: "10px",
            minWidth: "20px",
            maxWidth: "20px",
            backgroundColor: color,
            ...sx,
          }}
        />
      ))}
    </Stack>
  );
};

const FavoriteGrid = ({
  favorites,
  times,
  onRemoveFavorite,
}: {
  favorites: Array<Location>;
  times: Array<Date>;
  onRemoveFavorite(uuid: string): void;
}) => {
  const dates = {};

  times.map((t) => (dates[format(t, "P")] = t));

  const days = Object.values(dates);

  const dayToStokeIndexMappingFn = (
    day,
    tod: "morning" | "midday" | "afternoon"
  ) => {
    const idxs = times
      .map((time, i) => {
        if (format(time, "P") === format(day, "P")) {
          const hr = parseInt(format(time, "H"));
          switch (tod) {
            case "morning":
              return 6 < hr && hr <= 10 ? i : undefined;
            case "midday":
              return 10 < hr && hr <= 14 ? i : undefined;
            case "afternoon":
              return 14 < hr && hr <= 20 ? i : undefined;
          }
        }

        return undefined;
      })
      .filter((idx) => idx !== undefined);

    return idxs;
  };

  const dayToStokeIndex = days.map((d) => ({
    morning: dayToStokeIndexMappingFn(d, "morning"),
    midday: dayToStokeIndexMappingFn(d, "midday"),
    afternoon: dayToStokeIndexMappingFn(d, "afternoon"),
  }));

  const stokeAvFromDay = (dayIdx, stokes) => {
    const idxs = dayToStokeIndex[dayIdx];

    return {
      morning: stokes.reduce(
        (av, s, i) =>
          (idxs.morning.includes(i) ? s + av : av) / idxs.morning.length,
        0
      ),
      midday: stokes.reduce(
        (av, s, i) =>
          (idxs.morning.includes(i) ? s + av : av) / idxs.midday.length,
        0
      ),
      afternoon: stokes.reduce(
        (av, s, i) =>
          (idxs.morning.includes(i) ? s + av : av) / idxs.afternoon.length,
        0
      ),
    };
  };

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "grid",
          gap: 1,
          gridTemplateColumns: `repeat(${days.length + 1}, 1fr)`,
          backgroundColor: "background.light",
          borderRadius: "10px",
          overflowX: "scroll",
        }}
      >
        <Item sx={{ position: "sticky" }}>
          <Typography variant="subtitle1">Favorites</Typography>
        </Item>
        {days.map((day) => (
          <Item>
            <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
              {format(day as Date, "EEEE")}
            </Typography>
          </Item>
        ))}

        {favorites.map((favorite) => {
          return [
            <Item sx={{ position: "sticky" }}>
              <Stack direction={"row"} alignItems="center">
                <Button onClick={() => onRemoveFavorite(favorite.uuid)}>
                  <CancelIcon sx={{ color: "background.dark" }} />
                </Button>
                <Typography>{favorite.name}</Typography>
              </Stack>
            </Item>,
            ...days.map((day, i) => {
              const colors = ["red", "yellow", "green"];

              const stokes = stokeAvFromDay(i, favorite.stoke);

              return (
                <Item sx={{ alignItems: "center" }}>
                  <Bubbles
                    colors={[
                      climbingStokeToColor(stokes.morning),
                      climbingStokeToColor(stokes.midday),
                      climbingStokeToColor(stokes.afternoon),
                    ]}
                  ></Bubbles>
                </Item>
              );
            }),
          ];
        })}
      </Box>
    </Stack>

    // <Stack>
    //   <Stack direction={"row"} justifyContent="space-between">
    //     <Box></Box>
    //     {days.map((day) => (
    //       <Typography>{format(day, "EEEE")}</Typography>
    //     ))}
    //   </Stack>
    //   {favorites.map((favorite) => (
    //     <Stack direction={"row"} justifyContent="space-between">
    //       <Typography>{favorite.name}</Typography>
    //       {favorite.stokes.map((stoke) => (
    //         <Typography>{Math.trunc(stoke)}</Typography>
    //       ))}
    //     </Stack>
    //   ))}
    // </Stack>
  );
};

export default FavoriteGrid;
