import { LatLng } from "leaflet";

/**
 * Get user location from current IP -> doesn't work on HTTPS
 * @returns user location
 */
export const getLocationByIp = async (): Promise<LatLng | undefined> => {
  const resp = await (await fetch("http://ip-api.com/json/")).json();

  if (resp.lat && resp.lon) {
    return new LatLng(resp.lat, resp.lon);
  } else {
    return undefined;
  }
};
