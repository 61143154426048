import { LatLng } from "leaflet";

export enum ActivityType {
  CLIMBING,
  BIKING,
  HIKING,
}

export interface Stoke {
  score: number;
  date: Date;
}

export interface Location {
  name: string;
  uuid: string;
  coordinates: LatLng;
  activity: ActivityType;
  stoke: Array<number>;
  mountainProjectId: number | undefined;
  openBetaId: string | undefined;
  level: number;
  activityCount: number;
  url: string;
}
