import { differenceInDays, parse } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { LatLng } from "leaflet";
import { Location, ActivityType } from "../types";
import { compress, decompress, trimUndefinedRecursively } from "compress-json";

enum CACHE_KEYS {
  LOCATION_CACHE = "LOCATION_CACHE",
  FAVORITE_CACHE = "FAVORITE_CACHE",
}

const buildId = process.env?.REACT_APP_BUILD_ID;

export const parseDate = (date: string): Date =>
  zonedTimeToUtc(parse(date, "yyyyMMddHH", new Date()), "UTC");

export const formatInTimeZone = (date, fmt, tz) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const formatDate = (date: Date): string =>
  formatInTimeZone(date, "yyyyMMddHH", "UTC");

export const getCachedFavoritesUUIDs = (
  defaultFavorites: Array<string> = []
): Array<string> => {
  const rawCache = localStorage.getItem(CACHE_KEYS.FAVORITE_CACHE);

  if (rawCache) {
    return JSON.parse(rawCache);
  }

  return defaultFavorites;
};

export const writeCacheFavoriteUUIDs = (favorites: Array<string>) => {
  localStorage.setItem(CACHE_KEYS.FAVORITE_CACHE, JSON.stringify(favorites));
};

/**
 * Cache location and times in local storage
 * uses a simple compression algo to reduce space to under 10MB (which is the max on most browsers)
 * @param locations
 * @param times
 */
export const writeCache = (locations: Array<Location>, times: Array<Date>) => {
  trimUndefinedRecursively(locations);

  const compressedLocation = compress(locations);

  localStorage.setItem(
    CACHE_KEYS.LOCATION_CACHE,
    JSON.stringify({
      locations: compressedLocation,
      times,
      cacheTime: new Date().toISOString(),
      buildId,
    })
  );
};

/**
 * Read location / times cache if it exists
 * @returns
 */
export const readCache = () => {
  const cacheObject = JSON.parse(
    localStorage.getItem(CACHE_KEYS.LOCATION_CACHE) || "{}"
  );

  return {
    locations: cacheObject?.locations
      ? decompress(cacheObject?.locations)
      : undefined,
    times: cacheObject?.times
      ? cacheObject.times.map((time) => new Date(time))
      : undefined,
    cacheTime: cacheObject?.cacheTime,
    buildId: cacheObject?.buildId,
  };
};

/**
 * Fetch climbing/hiking/biking data from cache, otherwise grab json from backend
 * @param loadCompleteCallback callback to run after climbing data is finished loading
 * @returns
 */
export const fetchCachedLocationData = async (
  loadCompleteCallback: () => Promise<void> = async () => {}
): Promise<{
  locations: Array<Location>;
  times: Array<Date>;
}> => {
  const now = new Date();
  const cache = readCache();

  // Check if cache time is from today, or if the cache was using an old build of the app
  if (
    cache?.cacheTime &&
    buildId === cache?.buildId &&
    differenceInDays(now, new Date(cache?.cacheTime)) === 0
  ) {
    if (!cache.locations || !cache.times) {
      return {
        locations: [],
        times: [],
      };
    }

    console.log({ cacheTimes: cache?.times });

    return {
      locations: cache.locations,
      times: cache.times.map((time) => new Date(time)),
    };
  }

  const stokeDates = await (
    await fetch(
      "https://storage.googleapis.com/stoke_pilot/data/stoke_dates.geojson"
    )
  ).json();

  const climbingStoke = JSON.parse(
    (
      await (
        await fetch(
          "https://storage.googleapis.com/stoke_pilot/data/climbing_stoke.geojson"
        )
      ).text()
    ).replace(/\bNaN\b/g, "null") // there used to be an issue with NaNs breaking the JSON parser
  );

  console.log({ stokeDates });

  const locations = climbingGeoJsonToLocationArray(climbingStoke);

  const times = stokeDates.map(parseDate);

  console.log({ times });

  writeCache(locations, times);

  // fetch hiking and biking after climbing is complete
  (async () => {
    appendToCacheData(await asyncFetchData());
    loadCompleteCallback();
  })();

  return {
    locations,
    times,
  };
};

export const appendToCacheData = (locations: Array<Location>) => {
  const cache = readCache();
  writeCache([...cache.locations, ...locations], cache.times);
};

export const asyncFetchData = async (): Promise<Array<Location>> => {
  // fetch the biking and hiking data in parallel
  const data = await Promise.all([
    bikingGeoJsonToLocationArray(
      JSON.parse(
        (
          await (
            await fetch(
              "https://storage.googleapis.com/stoke_pilot/data/bikepath_stoke.geojson"
            )
          ).text()
        ).replace(/\bNaN\b/g, "null")
      )
    ),
    hikingGeoJsonToLocationArray(
      JSON.parse(
        (
          await (
            await fetch(
              "https://storage.googleapis.com/stoke_pilot/data/hiking_stoke.geojson"
            )
          ).text()
        ).replace(/\bNaN\b/g, "null")
      )
    ),
  ]);

  return [...data[0], ...data[1]];
};

export const climbingGeoJsonToLocationArray = (
  geojson: any
): Array<Location> => {
  return geojson?.features.map((feature, i) => ({
    uuid: `${feature?.properties?.i}_climbing`,
    openBetaId: feature.properties.uuid,
    name: feature.properties?.Name || "Climbing Area",
    coordinates: new LatLng(
      feature.geometry.coordinates[1],
      feature.geometry.coordinates[0]
    ),
    activity: ActivityType.CLIMBING,
    stoke: feature?.properties?.Scores || [],
    mountainProjectId: feature?.properties?.mpid,
    level: feature?.properties?.Level || 2,
    activityCount: feature.properties?.Total,
  }));
};

export const hikingGeoJsonToLocationArray = (geojson: any): Array<Location> => {
  return geojson?.features.map((feature, i) => ({
    uuid: `${feature?.properties?.i}_hiking`,
    name: feature.properties?.Name || "Hiking Area",
    coordinates: new LatLng(
      feature.geometry.coordinates[1],
      feature.geometry.coordinates[0]
    ),
    activity: ActivityType.HIKING,
    stoke: feature?.properties?.Scores || [],
    level: feature?.properties?.Level || 2,
    activityCount: feature.properties?.Len,
    url: feature.properties?.url,
  }));
};

export const bikingGeoJsonToLocationArray = (geojson: any): Array<Location> => {
  return geojson?.features.map((feature, i) => ({
    uuid: `${feature?.properties?.i}_biking`,
    name: feature.properties?.Name || "Biking Area",
    coordinates: new LatLng(
      feature.geometry.coordinates[1],
      feature.geometry.coordinates[0]
    ),
    activity: ActivityType.BIKING,
    stoke: feature?.properties?.Scores || [],
    level: feature?.properties?.Level || 2,
    activityCount: feature.properties?.Len,
    url: feature.properties?.url,
  }));
};
