import { Paper, Stack, Typography, Box } from "@mui/material";
import Location from "./location";

const LocationList = ({
  locations,
  currentTimeIdx,
  setHoverLocationUUID,
  hoverLocationUUID,
  onToggleFavorite,
  favoriteUUIDs,
  ref,
}) => {
  return (
    <Stack
      id={"location_list"}
      spacing={1}
      sx={{
        width: "100%",
        overflowY: "scroll",
        height: { lg: "70vh", md: "40vh" },
        borderRadius: "10px",
        "::-webkit-scrollbar-track": {
          display: "none",
        },
      }}
      onMouseLeave={() => setHoverLocationUUID(undefined)}
      ref={ref}
    >
      {locations.map((location, i) => (
        <Location
          favorited={favoriteUUIDs.includes(location.uuid)}
          onToggleFavorite={() => onToggleFavorite(location.uuid)}
          highlighted={hoverLocationUUID === location.uuid}
          location={location}
          currentTimeIdx={currentTimeIdx}
          key={i}
          onMouseOver={() => setHoverLocationUUID(location.uuid)}
        />
      ))}
    </Stack>
  );
};

export default LocationList;
