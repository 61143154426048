// @ts-nocheck -> hack for now
import { createTheme } from "@mui/material/styles";

export const spacing = (s) => `${s * 5}px`;

export const breakpoints = {
  values: {
    xs: 0,
    sm: 0,
    md: 0, // phone
    lg: 740, // desktop
  },
};

const palette = {
  primary: {
    main: "#6EC1E4",
  },
  secondary: {
    main: "#54595F",
    contrastText: "#AEAEAE",
  },
  success: {
    main: "#61CE70",
  },
  background: {
    main: "#FFF",
    light: "#F9F9F9",
    dark: "#D0D0D0",
  },
};

export const theme = createTheme({
  palette,
  typography: {
    fontFamily: "Poppins, sans-serif",
    color: palette.secondary.contrastText,
    subtitle1: {
      fontWeight: 600,
    },
    caption: {},
  },
  breakpoints,
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: "20px",
          backgroundColor: "none",
          width: "fit-content",
          "&:hover": {
            //you want this to be the same as the backgroundColor above
            opacity: "80%",
            backgroundColor: "none",
          },
          "&:disabled": {
            //you want this to be the same as the backgroundColor above
            opacity: "30%",
            backgroundColor: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#c36",
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "item" },
          style: {
            padding: spacing(2),
            height: "120px",
          },
        },
      ],
    },
    MuiSlider: {
      variants: [
        {
          props: { variant: "map" },
          style: {},
        },
      ],
    },
  },
});

// update types for new variant

declare module "@mui/material/styles" {
  interface PaperVarients {
    item: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaperVariantsOptions {
    item?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    item: true;
  }
}
