import { Paper, Stack, Typography, Box, Button } from "@mui/material";
import { climbingStokeToColor, stokeToString } from "../api/stoke";
import { ActivityType, Location as LocationType } from "../types";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import WeatherScoreTimeline from '../components/WeatherScoreTimeline';

const renderSubtext = (location: LocationType) => {
  switch (location.activity) {
    case ActivityType.BIKING:
      return ` | ${location.activityCount} miles of trail`;
    case ActivityType.CLIMBING:
      return ` | ${location.activityCount} routes`;
    case ActivityType.HIKING:
      return ` | ${location.activityCount} acres`;
  }
};

const renderLinkButtons = (location: LocationType) => {
  switch (location.activity) {
    case ActivityType.BIKING:
      return (
        <Stack direction={"row"} alignItems="center" spacing={0}>
          <Button
            href={location.url}
            target={"_blank"}
            disabled={!location.url}
          >
            <Box
              sx={{ width: "20px", height: "20px", borderRadius: "2px" }}
              component={"img"}
              src={"/trail_link.png"}
            ></Box>
          </Button>
        </Stack>
      );
    case ActivityType.CLIMBING:
      return (
        <Stack direction={"row"} alignItems="center" spacing={0}>
          <Button
            href={`https://openbeta.io/crag/${location.openBetaId}`}
            target={"_blank"}
            disabled={!location.openBetaId}
          >
            <Box
              sx={{ width: "20px", height: "20px" }}
              component={"img"}
              src={"/open_beta.png"}
            ></Box>
          </Button>
          <Button
            href={`https://www.mountainproject.com/area/${location.mountainProjectId}`}
            target={"_blank"}
            disabled={!location.mountainProjectId}
          >
            <Box
              sx={{ width: "20px", height: "20px" }}
              component={"img"}
              src={"/mp.png"}
            ></Box>
          </Button>
        </Stack>
      );
    case ActivityType.HIKING:
      return (
        <Stack direction={"row"} alignItems="center" spacing={0}>
          <Button
            href={location.url}
            target={"_blank"}
            disabled={!location.url}
          >
            <Box
              sx={{ width: "20px", height: "20px" }}
              component={"img"}
              src={"/all_trails.png"}
            ></Box>
          </Button>
        </Stack>
      );
  }
};

const Location = ({
  highlighted,
  favorited,
  location,
  currentTimeIdx,
  onMouseOver = () => {},
  onClick = () => {},
  onToggleFavorite = () => {},
}: {
  highlighted: boolean;
  favorited: boolean;
  location: LocationType;
  currentTimeIdx: number;
  onMouseOver?: () => void;
  onClick?: () => void;
  onToggleFavorite?: () => void;
}) => (
  <Paper
    id={"location_id_" + location.uuid}
    variant="item"
    sx={{
      height: "fit-content",
      backgroundColor: highlighted ? "background.dark" : "background.light",
      borderRadius: "10px",
    }}
    onMouseOver={onMouseOver}
    onClick={onClick}
  >
    <Stack direction={"row"} justifyContent="start" spacing={2}>
      <Box
        sx={{
          borderRadius: "15px",
          width: "15px",
          backgroundColor: climbingStokeToColor(location.stoke[currentTimeIdx]),
          position: "relative",
        }}
      >
        <Typography
          sx={{
            m: 0,
            position: "absolute",
            color: "white",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%) rotate(90deg);",

            verticalAlign: "middle",
            textAlign: "center",
            whiteSpace: "nowrap",
            textTransform: "uppercase",
            fontSize: 8,
            fontWeight: 600,
          }}
          variant={"caption"}
        >
          {stokeToString(location.stoke[currentTimeIdx])}
        </Typography>
      </Box>

      <Stack sx={{ width: "100%" }}>
        <Stack direction={"row"} justifyContent="start" sx={{ width: "100%" }}>
          <Stack sx={{ width: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              sx={{ width: "100%" }}
            >
              <Typography variant="subtitle1">{location.name}</Typography>
              

              <Stack direction={"row"} alignItems={"center"}>
                {renderLinkButtons(location)}
                <Button onClick={onToggleFavorite}>
                  {favorited ? (
                    <StarIcon sx={{ color: "secondary.contrastText" }} />
                  ) : (
                    <StarBorderIcon sx={{ color: "secondary.contrastText" }} />
                  )}
                </Button>
              </Stack>
            </Stack>

            <Typography variant="caption">
              <span>
                <WeatherScoreTimeline location={location}/>
                {renderSubtext(location)}
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Paper>
);

export default Location;
