import { Box } from "@mui/material";
import React, { MutableRefObject } from "react";
import { breakpoints } from "../theme";

export const fromViewportPadding = () => {
  return {
    md: `calc((100vw - ${breakpoints.values.md}px) / 2)`,
    lg: `calc((100vw - ${breakpoints.values.lg}px) / 2)`,
  };
};

export const MainContentBox = ({
  children,
  passedRef = undefined,
  sx = {},
}: {
  children: any;
  passedRef?: MutableRefObject<HTMLDivElement> | undefined;
  sx?: any;
}) => (
  <Box
    sx={{
      boxSizing: "border-box",
      maxWidth: {
        lg: "1250px", // about
      },
      minWidth: {
        lg: "971px",
      },

      width: "100%",
      height: "100%",
      mr: "auto",
      ml: "auto",
      pl: { md: "10px" },
      pr: { md: "10px" },
      ...sx,
    }}
  >
    {!!passedRef ? (
      <div id="MainContentBox" ref={passedRef}>
        {children}
      </div>
    ) : (
      <div id="MainContentBox">{children}</div>
    )}
  </Box>
);
