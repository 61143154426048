import * as React from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const MapSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 10,
    width: 2,
    borderRadius: 0,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
    },
  },

  "& .MuiSlider-valueLabel::before": {
    left: "50%",
  },

  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: "white",
    width: "100px",
    justifyContent: "start",
    color: theme.palette.text.primary,
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },

  "& .MuiSlider-track": {
    border: "none",
    height: 10,
    borderRadius: 0,
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: { lg: "#fff", md: "#303030" },
    height: 10,
    borderRadius: 0,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 0,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

export default MapSlider;
