import { Box, Typography } from "@mui/material";
import { LatLng, LatLngBounds } from "leaflet";
import { useEffect, useMemo, useRef, useState } from "react";
import LocationList from "../components/location_list";
import Map from "../components/map";
import DefaultView from "../views/default_view";
import TrimodalView from "../views/trimodal_view";
import {
  fetchCachedLocationData,
  formatDate,
  getCachedFavoritesUUIDs,
  writeCacheFavoriteUUIDs,
} from "../api/data";
import { ActivityType, Location } from "../types";
import { getLocationByIp } from "../api/location";
import FavoriteGrid from "../components/favorite_grid";
import DebugWindow from "../debug_window";
import WeatherScoreTimeline from '../components/WeatherScoreTimeline';

export const includeTime = (time: Date) => {
  return time.getHours() >= 6 && time.getHours() <= 20 && time > new Date();
};

const Home = () => {
  const [userLocation, setUserLocation] = useState<LatLng | undefined>(
    undefined
  );
  const [locations, setLocations] = useState<Array<Location>>([]);
  const [mode, setMode] = useState<ActivityType>(ActivityType.CLIMBING);
  const [favoriteUUIDs, setFavoriteUUIDs] = useState<Array<string>>(
    getCachedFavoritesUUIDs([
      "631_climbing", // yosemite
      "3284_climbing", // red rocks
    ])
  );
  const [times, setTimes] = useState<Array<Date>>([]);
  const [timeIndex, setTimeIndex] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(5);
  const [hoverLocationUUID, setHoverLocationUUID] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      try {
        setUserLocation(
          (await getLocationByIp()) || new LatLng(36.114647, -115.172813) // VEGAS BABY
        );
      } catch {
        setUserLocation(new LatLng(36.114647, -115.172813));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // load climbing first
      const { locations, times } = await fetchCachedLocationData(async () => {
        // load biking and hiking
        const { locations: locationsFull } = await fetchCachedLocationData();
        setLocations(locationsFull);
      });

      setLocations(locations);
      setTimes(times);
      setTimeIndex(times.indexOf(times.filter(includeTime)[0])); // set time index to first "allowed" time
    })();
  }, []);

  const [bounds, setBounds] = useState<LatLngBounds | undefined>(undefined);

  const onMapBoundsChange = (bounds: LatLngBounds) => {
    setBounds(bounds);
  };

  const { sideBarLocations, mapRenderedLocations } = useMemo(() => {
    const inBoundLocations = bounds
      ? locations
          .filter((l) => mode === l.activity)
          .filter((location) => {
            if (location.level === 2) {
              return zoom >= 8;

            } else if (location.level === 1) {
              return zoom < 8;
            }
          })

          .filter((location) => bounds.contains(location.coordinates))
      : [];

    inBoundLocations.sort((l, r) => r.stoke[timeIndex] - l.stoke[timeIndex]);

    // limit display to the first 200 locations
    const renderedLocations = inBoundLocations.slice(0, 200);

    return {
      inBoundLocations,
      sideBarLocations: renderedLocations,
      mapRenderedLocations: renderedLocations,
    };
  }, [locations, bounds, timeIndex, zoom, mode]);

  const updateFavorites = (favorites: Array<string>) => {
    writeCacheFavoriteUUIDs(favorites);
    setFavoriteUUIDs(favorites);
  };

  const debugMode =
    new URL(window.location.toString()).searchParams.get("debug") === "true";

  const ref = useRef(null);

  return (
    <DefaultView>
      {debugMode && (
        <DebugWindow
          data={{
            timeIndex,
            time: times[timeIndex],
            mapUrl: times.length ? formatDate(times[timeIndex]) : undefined,
            bounds: `center: [${bounds?.getCenter().lat.toFixed(6)}, ${bounds
              ?.getCenter()
              .lng.toFixed(6)}`,
            mode,
            zoom,
            hoverLocationUUID,
            selectedStoke: hoverLocationUUID
              ? locations.filter((l) => l.uuid === hoverLocationUUID)[0].stoke[
                  timeIndex
                ]
              : undefined,

            stokeL: locations.length ? locations[0].stoke.length : undefined,
            timeL: times.length ? times.length : undefined,
          }}
        ></DebugWindow>
      )}
      <Typography variant="subtitle1">
        Locations, ranked by condition
      </Typography>
      <TrimodalView
        bottom={
          <Box>
            <FavoriteGrid
              favorites={locations.filter((l) => favoriteUUIDs.includes(l.uuid)
              )}
              times={times}
              onRemoveFavorite={(uuid) => {
                updateFavorites(favoriteUUIDs.filter((u) => u !== uuid));
              } } />
          </Box>
        }
        left={
          <Box sx={{ width: "100%", height: "100%" }}>
            <LocationList
              locations={sideBarLocations}
              favoriteUUIDs={favoriteUUIDs}
              currentTimeIdx={timeIndex}
              setHoverLocationUUID={setHoverLocationUUID}
              ref={ref}
              hoverLocationUUID={hoverLocationUUID}
              onToggleFavorite={(uuid) => {
                if (favoriteUUIDs.includes(uuid)) {
                  updateFavorites(favoriteUUIDs.filter((u) => u !== uuid));
                } else {
                  updateFavorites([...favoriteUUIDs, uuid]);
                }
              }}
            ></LocationList>
          </Box>
        }
        right={
          userLocation &&
          locations.length > 0 && (
            <Box>
              <Map
                center={userLocation}
                timeIndex={timeIndex}
                onMapBoundChange={onMapBoundsChange}
                onChangeTimeIndex={setTimeIndex}
                sx={{ height: { md: "40vh", lg: "70vh" } }}
                locations={mapRenderedLocations}
                hoverLocationUUID={hoverLocationUUID}
                times={times}
                mode={mode}
                zoom={zoom}
                onChangeMode={(mode) => setMode(mode)}
                onLocationHover={(location) =>
                  setHoverLocationUUID(location.uuid)
                }
                onLocationEnd={(location) => setHoverLocationUUID(undefined)}
                onLocationClicked={(location) => {
                  // need to auto scroll the element - this is a total hack, but works
                  const scrollEl = document.querySelector(
                    "#location_list"
                  ) as HTMLElement;
                  const el = document.querySelector(
                    "#location_id_" + location.uuid
                  ) as HTMLElement;

                  // y is just relative height of element
                  const y =
                    el.getBoundingClientRect().top -
                    scrollEl.getBoundingClientRect().top;
                  scrollEl.scrollTo({
                    // don't need to scroll if already at y posn
                    top: y + scrollEl.scrollTop,
                    behavior: "smooth",
                  });
                }}
                onMapZoomLevelChange={(zoom) => setZoom(zoom)}
              />
            </Box>
          )
        }
      />
    </DefaultView>
  );
};

export default Home;
