const DebugWindow = ({ data }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        border: "1px solid black",
        opacity: 0.5,
        zIndex: 100000,
        pointerEvents: "none",
      }}
    >
      {Object.entries(data).map(([k, v]) => (
        <div>{`${k}:${v}`}</div>
      ))}
    </div>
  );
};

export default DebugWindow;
