import { useTheme } from "@mui/material";

export const useColor = (color: string) => {
  const theme = useTheme();

  if (color in theme.palette) {
    return theme.palette[color].main;
  }

  return color;
};
