import { ReactNode } from "react";
import { Stack, Box, Grid } from "@mui/material";

const TrimodalView = ({
  bottom,
  left,
  right,
}: {
  bottom: ReactNode;
  left: ReactNode;
  right: ReactNode;
}) => (
  <Grid container spacing={2} rowSpacing={3}>
    <Grid item md={12} lg={4} order={{ lg: 1, md: 2 }}>
      {left}
    </Grid>
    <Grid item md={12} lg={8} order={{ lg: 2, md: 1 }}>
      {right}
    </Grid>
    <Grid item md={12} order={{ lg: 3, md: 3 }}>
      {bottom}
    </Grid>
  </Grid>
);

export default TrimodalView;
