import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell } from 'recharts';
import chroma from 'chroma-js';
import { ActivityType, Location } from "../types";

// Modal styles
const customStyles: Modal.Styles = {
  overlay: {
    zIndex: 1000, // value higher than the z-index of the map
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '60%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
  },
};

Modal.setAppElement('#root');

interface WeatherDataPoint {
  name: string;
  score: number;
}

interface GradientLegendProps {
  min: number;
  max: number;
}

interface WeatherScoreTimelineProps {
  location: Location;
}

const GradientLegend: React.FC<GradientLegendProps> = ({ min, max }) => {
  const colorScale = chroma.scale(['red', 'orange', 'green']).domain([min, max]);
  const gradientId = 'weather-score-gradient';

  return (
    <div>
      <svg width="50" height="200">
        <defs>
          <linearGradient id={gradientId} x1="0%" y1="100%" x2="0%" y2="0%">
            {colorScale.colors(101).map((color, index) => (
              <stop key={index} offset={`${index}%`} stopColor={color} />
            ))}
          </linearGradient>
        </defs>
        <rect x="15" y="0" width="20" height="200" fill={`url(#${gradientId})`} />
        <text x="25" y="15" textAnchor="middle" fill="white" fontSize="12">{max}</text>
        <text x="25" y="195" textAnchor="middle" fill="white" fontSize="12">{min}</text>
      </svg>
    </div>
  );
};

const WeatherScoreTimeline: React.FC<WeatherScoreTimelineProps> = ({ location }) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [weatherData, setWeatherData] = useState<WeatherDataPoint[]>([]);

  useEffect(() => {
    const generateWeatherData = (): WeatherDataPoint[] => {
      const data: WeatherDataPoint[] = [];
      const currentDate = new Date();

      location.stoke.forEach((score, index) => {
        const date = new Date(currentDate.getTime() + index * 60 * 60 * 1000); // Increment by hours
        const day = date.toLocaleDateString('en-US', { weekday: 'short', hour: '2-digit', hour12: false });
        data.push({ name: day, score });
      });

      return data;
    };

    setWeatherData(generateWeatherData());
  }, [location]);

  const getWeatherData = async (): Promise<WeatherDataPoint[]> => {
    // Replace this with a real API call to fetch weather data
    const data: WeatherDataPoint[] = [
      { name: 'Sun 1', score: 75 },
      { name: 'Mon 1', score: 70 },
      { name: 'Tue 1', score: 80 },
      { name: 'Wed 1', score: 68 },
      { name: 'Thu 1', score: 77 },
      { name: 'Fri 1', score: 83 },
      { name: 'Sat 1', score: 64 },
      { name: 'Sun 2', score: 72 },
      { name: 'Mon 2', score: 65 },
      { name: 'Tue 2', score: 85 },
      { name: 'Wed 2', score: 69 },
      { name: 'Thu 2', score: 74 },
      { name: 'Fri 2', score: 81 },
      { name: 'Sat 2', score: 67 },
    ];
    return data;
  };

  const getBarColor = (score: number): string => {
    const colorScale = chroma.scale(['red', 'orange', 'green']).domain([0, 100]);
    return colorScale(score).hex();
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <span className="no-wrap" onClick={openModal}>Forecast</span>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Stoke Score Timeline">
      <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
        >
          &times;
        </button>
        <h2>Stoke score for {location.name}</h2>
        <div style={{ display: 'flex' }}>
          <BarChart width={640} height={300} data={weatherData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="score">
              {weatherData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(entry.score)} />
              ))}
            </Bar>
          </BarChart>
          <GradientLegend min={0} max={100} />
        </div>
        <p>
          The weather score timeline for {location.name} provides an overview of the expected weather conditions for
          various outdoor activities such as {location.activity}. The location has {location.activityCount} activities
          with a difficulty level of {location.level}. For more information about {location.name}, you can visit the
          official website at <a href={location.url} target="_blank" rel="noopener noreferrer">{location.url}</a>.
        </p>
      </Modal>
    </div>
  );
};

export default WeatherScoreTimeline;
