import {
  MapContainer,
  TileLayer,
  ImageOverlay,
  CircleMarker,
  Popup,
  useMapEvents,
  Marker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  icon,
  Icon,
  IconOptions,
  LatLng,
  LatLngBounds,
  PointExpression,
} from "leaflet";
import { Box, Button, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { ActivityType, Location } from "../types";
import { formatDate } from "../api/data";
import MapSlider from "./map_slider";
import { differenceInDays, format } from "date-fns";
import Climbing from "../assets/climbing.png";
import { includeTime } from "../pages/home";
import { colors } from "../api/stoke";

// set up latlng boundary for stoke score image overlay
const llcorner = new LatLng(24.375,-125.125);
const urcorner = new LatLng(50.125,-66.375);
const image_bounds = new LatLngBounds(llcorner,urcorner);

const MapEventHandler = ({ onMapBoundChange, onMapZoomLevelChange }) => {
  const map = useMapEvents({
    load: () => {
      onMapBoundChange(map.getBounds());
    },
    drag: () => {
      onMapBoundChange(map.getBounds());
    },
    zoom: () => {
      onMapBoundChange(map.getBounds());
      onMapZoomLevelChange(map.getZoom());
    },
  });

  useEffect(() => {
    // on load hook doesn't seem to work, need to set init bounds here
    onMapBoundChange(map.getBounds());
  }, []);

  return <></>;
};

const renderIcon = (acitivty: ActivityType, highlighted: boolean) => {
  const iconSize = highlighted ? [24, 24] : [16, 16];

  switch (acitivty) {
    case ActivityType.BIKING:
      return new Icon({
        iconUrl: "/biking_icon.svg",
        iconSize: iconSize as PointExpression,
      });

    case ActivityType.CLIMBING:
      return new Icon({
        iconUrl: "/climbing_icon.svg",
        iconSize: iconSize as PointExpression,
      });

    case ActivityType.HIKING:
      return new Icon({
        iconUrl: "/hiking_icon.svg",
        iconSize: iconSize as PointExpression,
      });
  }
};

const renderTimeTooltip = (time: Date): string => {
  if (format(time, "P") === format(new Date(), "P")) {
    return "Today" + format(time, " haaa");
  }
  return format(time, "EEEE haaa");
};

const renderLabel = (time: Date, index: number): string | undefined => {
  if (index === 0) {
    return "Today";
  }

  if (!(time.getHours() === 6 && differenceInDays(time, new Date()) < 8)) {
    return undefined;
  }

  return format(time, "EEEE");
};

const Map = ({
  timeIndex,
  zoom,
  hoverLocationUUID,
  onMapBoundChange = (bounds) => {},
  onMapZoomLevelChange = (zoom) => {},
  onLocationClicked = (location) => {},
  onLocationHover = (location) => {},
  onLocationEnd = (location) => {},
  onChangeTimeIndex = (index) => {},
  onChangeMode = (mode) => {},

  mode,
  sx = {},
  locations = [],
  times = [],
  center,
}: {
  timeIndex: number;
  zoom: number;
  hoverLocationUUID: string | undefined;
  onMapBoundChange?(bounds: LatLngBounds): void;
  onMapZoomLevelChange?(zoom: number): void;
  onLocationClicked?(location: Location): void;
  onLocationHover?(location: Location): void;
  onLocationEnd?(location: Location): void;
  onChangeTimeIndex?(index: number): void;
  onChangeMode(mode): void;
  mode: ActivityType;
  sx?: any;
  locations?: Array<Location>;
  times?: Array<Date>;
  center: LatLng;
}) => {
  const filteredTimes = times.filter(includeTime);

  return (
    <Box sx={{ position: "relative", mb: { lg: "0px", md: "50px" } }}>
      <Box
        sx={{
          zIndex: 0,
          ...sx,
        }}
      >
        <MapContainer
          center={center} // las vegas -> est location?
          zoom={zoom}
          scrollWheelZoom={true}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: "10px",
          }}
          maxZoom={10}
          minZoom={4}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
          />

          {mode === ActivityType.CLIMBING && (
            //<TileLayer
            //  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //  tms={true}
            //  url={
            //    "https://storage.googleapis.com/stoke_pilot/tiles/climbing/" +
            //    formatDate(times[timeIndex]) +
            //    "/{z}/{x}/{y}.png"
            //  }
            //  opacity={0.33}
            ///>
            <ImageOverlay
              url={
                "https://storage.googleapis.com/stoke_pilot/tiles/climbing/" +
                formatDate(times[timeIndex]) + "/stoke_wm_p.png"
              }
              bounds={image_bounds}
              opacity={0.33}
            />  
          )}

          {mode === ActivityType.BIKING && (
            // <TileLayer
            //   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //   tms={true}
            //   url={
            //     "https://storage.googleapis.com/stoke_pilot/tiles/bikepath/" +
            //     formatDate(times[timeIndex]) +
            //     "/{z}/{x}/{y}.png"
            //   }
            //   opacity={0.33}
            // />
            <ImageOverlay
              url={
                "https://storage.googleapis.com/stoke_pilot/tiles/bikepath/" +
                formatDate(times[timeIndex]) + "/stoke_wm_p.png"
              }
              bounds={image_bounds}
              opacity={0.33}
            /> 
          )}

          {mode === ActivityType.HIKING && (
            // <TileLayer
            //   attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            //   tms={true}
            //   url={
            //     "https://storage.googleapis.com/stoke_pilot/tiles/hiking/" +
            //     formatDate(times[timeIndex]) +
            //     "/{z}/{x}/{y}.png"
            //   }
            //   opacity={0.33}
            // />
            <ImageOverlay
              url={
                "https://storage.googleapis.com/stoke_pilot/tiles/hiking/" +
                formatDate(times[timeIndex]) + "/stoke_wm_p.png"
              }
              bounds={image_bounds}
              opacity={0.33}
            /> 
          )}
          <MapEventHandler
            onMapBoundChange={onMapBoundChange}
            onMapZoomLevelChange={onMapZoomLevelChange}
          />

          {locations.map((location, i) => (
            <Marker
              key={i}
              title={location.name}
              position={[location.coordinates.lat, location.coordinates.lng]}
              icon={renderIcon(
                location.activity,
                location.uuid === hoverLocationUUID
              )}
              eventHandlers={{
                click: (e) => onLocationClicked(location),
                mouseover: (e) => onLocationHover(location),
                mouseout: (e) => onLocationEnd(location),
              }}
            ></Marker>
          ))}
        </MapContainer>
      </Box>

      <div
        style={{
          position: "absolute",
          bottom: "30px",
          right: "10px",
          transform: "translate3d(0px, 0px, -10px)",
          zIndex: 900,
        }}
      >
        <svg viewBox="0 0 100 10" style={{ width: "120px", height: "12px" }}>
          <defs>
            <linearGradient id="myGradient">
              <stop offset="0%" stop-color={colors[0]} />
              <stop offset="50%" stop-color={colors[1]} />
              <stop offset="100%" stop-color={colors[2]} />
            </linearGradient>
          </defs>

          <rect
            x={0}
            y={0}
            width={100}
            height={10}
            rx={5}
            fill="url('#myGradient')"
          />
          <text
            x={5}
            y={5.7}
            height={10}
            textAnchor="start"
            dominantBaseline={"middle"}
            fill={"white"}
            style={{
              fontSize: 8,
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {`poor`}
          </text>

          <text
            x={95}
            y={5.7}
            height={10}
            textAnchor="end"
            dominantBaseline={"middle"}
            fill={"white"}
            style={{
              fontSize: 8,
              fontWeight: 600,
              textTransform: "uppercase",
            }}
          >
            {`good`}
          </text>
        </svg>
      </div>
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          transform: "translate3d(0px, 0px, -10px)",
          zIndex: 900,
        }}
      >
        <Box
          sx={{ backgroundColor: "white", borderRadius: "0px 0px 0px 10px" }}
        >
          <Button
            sx={{ opacity: mode === ActivityType.CLIMBING ? 1 : 0.6 }}
            onClick={() => {
              if (mode !== ActivityType.CLIMBING)
                onChangeMode(ActivityType.CLIMBING);
            }}
          >
            <Box
              sx={{ width: "25px", height: "25px", borderRadius: "2px" }}
              component={"img"}
              src={"/climbing_icon.svg"}
            ></Box>
          </Button>
          <Button
            sx={{ opacity: mode === ActivityType.BIKING ? 1 : 0.6 }}
            onClick={() => {
              if (mode !== ActivityType.BIKING)
                onChangeMode(ActivityType.BIKING);
            }}
          >
            <Box
              sx={{ width: "25px", height: "25px", borderRadius: "2px" }}
              component={"img"}
              src={"/biking_icon.svg"}
            ></Box>
          </Button>
          <Button
            sx={{ opacity: mode === ActivityType.HIKING ? 1 : 0.6 }}
            onClick={() => {
              if (mode !== ActivityType.HIKING)
                onChangeMode(ActivityType.HIKING);
            }}
          >
            <Box
              sx={{ width: "25px", height: "25px", borderRadius: "2px" }}
              component={"img"}
              src={"/hiking_icon.svg"}
            ></Box>
          </Button>
        </Box>
      </div>

      <Box
        sx={{
          position: "absolute",
          bottom: { lg: "0px", md: "-50px" },
          left: { lg: "70px", md: "50px" },
          right: { lg: "250px", md: "50px" },
          transform: "translate3d(0px, 0px, -10px)",
          zIndex: 900,
        }}
      >
        <MapSlider
          step={null}
          max={times.indexOf(filteredTimes[filteredTimes.length - 1])}
          min={times.indexOf(filteredTimes[0])}
          value={timeIndex}
          marks={filteredTimes.map((time, i) => ({
            value: times.indexOf(time),
            label: renderLabel(time, i),
          }))}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => renderTimeTooltip(times[value])}
          onChange={(event, value) => onChangeTimeIndex(value as number)}
        ></MapSlider>
      </Box>
    </Box>
  );
};

export default Map;
